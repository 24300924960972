<template>
  <a-modal
    :title="title"
    :maskClosable=false
    :width="1000"
    :visible="visible"
    :confirmLoading="confirmLoading"
    :footer="null"
    @cancel="handleCancel">

    <a-steps v-if="model.refundWay==1" :current="current" size="small">
      <a-step title="买家申请退货退款"/>
      <a-step title="卖家处理退货申请"/>
      <a-step title="买家退货"/>
      <a-step title="退款完毕"/>
    </a-steps>

    <a-steps v-if="model.refundWay==2" :current="current" size="small">
      <a-step title="买家申请仅退款"/>
      <a-step title="卖家处理退款申请"/>
      <a-step title="退款完毕"/>
    </a-steps>

    <div class="refundContent">
      <div class="contentLeft">
        <div style="margin-top: 40px;margin-left: 20px;margin-right: 20px;">
          <div>
            <!--//申请状态 1:申请中  2:退款失败 3:退款成功 4:已撤销
            // 5:等待卖家处理（卖家同意退货回款申请）
            // 6:等待买家发货 7:等待卖家收货（等待卖家退款）-->
            <p style="font-size: 16px;font-weight: bold;">
              <template v-if="model.refundStatus==5">请处理退货退款申请</template>
              <template v-if="model.refundStatus==6">请等待买家退货</template>
              <!--<template v-if="model.refundStatus==7">买家已退货</template>-->
              <template v-if="model.refundStatus==1&&model.refundWay==1">退货已签收，请处理退款申请</template>
              <template v-if="model.refundStatus==1&&model.refundWay==2">请处理退款申请</template>
              <template v-if="model.refundStatus==2">卖家已拒绝退款</template>
              <template v-if="model.refundStatus==3">退款成功</template>
              <template v-if="model.refundStatus==4">退款撤销</template>
              <template v-if="model.refundStatus==8">退款中...</template>
            </p>

            <div v-if="model.refundStatus==2" style="color: #6c6c6c;line-height: 1.5;">
              拒绝原因：{{model.memo}}
            </div>

            <div v-if="model.refundStatus==3" style="color: #6c6c6c;line-height: 1.5;">
              退款成功时间:{{model.refundTime}}<br/>
              退款金额：￥ {{model.refundMoney}}
            </div>

            <div v-if="model.refundStatus==4" style="color: #6c6c6c;line-height: 1.5;">
              因买家取消退款申请，退款已关闭，交易将正常进行
            </div>

            <div v-if="model.refundStatus==7">
              <p style="display: inline-block;font-weight: bold;">买家已退货 :
                {{model.refundShippingCompany}}({{model.refundShippingCode}})</p>
              <a style="margin-left: 20px;" @click="logisticsDetails">查看物流详情</a>
            </div>

            <!--状态为 2：退款失败 3：退款成功  4：已撤销 8：退款中 时不显示操作按钮-->
            <template v-if="model.refundStatus!=2&&model.refundStatus!=3&&model.refundStatus!=4&&model.refundStatus!=8">

              <a-divider/>

              <div>

                <a-button
                  v-if="model.refundStatus==1||model.refundStatus==5||model.refundStatus==6||model.refundStatus==7"
                  type="primary" @click="agreeRefund">
                  <template v-if="model.refundWay==1&&model.refundStatus==1">确认收货，同意退款</template>
                  <template v-if="model.refundWay==1&&model.refundStatus==5">同意退货退款申请</template>
                  <template v-if="model.refundWay==1&&(model.refundStatus==6||model.refundStatus==7)">已收到货，同意退款</template>
                  <template v-if="model.refundWay==2">同意退款</template>
                </a-button>

                <a-button v-if="model.refundStatus!=6" style="margin-left: 20px;" @click="refuseRefund">
                  拒绝退款
                </a-button>
              </div>
            </template>

          </div>
        </div>
      </div>
      <div class="contentRight">
        <div>
          <strong style="font-size: 16px;">退款详情</strong>
        </div>

        <a-divider style="margin: 12px 0;"/>

        <div>
          <img class="contentImg" :src="url.imgerver+'/'+model.goodsPicture"/>
          <div class="content" style="color: #333;font-size: 14px;">
            <span>{{model.goodsName}}</span>
          </div>
          <div style="clear: both;"></div>
        </div>

        <a-divider/>

        <div>
          <span class="contentTiele">买家 </span>
          <sapn style="float: left;">:</sapn>
          <div class="content">{{model.userName}}</div>
          <div style="clear: both;"></div>
        </div>
        <div>
          <span class="contentTiele">订单编号</span>
          <sapn style="float: left;">:</sapn>
          <div class="content">{{model.orderNo}}</div>
          <div style="clear: both;"></div>
        </div>
        <div>
          <span class="contentTiele">成交时间</span>
          <sapn style="float: left;">:</sapn>
          <div class="content">{{model.createTime}}</div>
          <div style="clear: both;"></div>
        </div>
        <div>
          <span class="contentTiele">单价</span>
          <sapn style="float: left;">:</sapn>
          <div class="content">￥{{model.price}}*{{model.num}}(数量)</div>
          <div style="clear: both;"></div>
        </div>

        <div>
          <span class="contentTiele">商品总价</span>
          <sapn style="float: left;">:</sapn>
          <div class="content">￥{{model.goodsMoney}}</div>
          <div style="clear: both;"></div>
        </div>

        <a-divider style="margin: 2px 0"/>

        <div>
          <span class="contentTiele">退款金额</span>
          <sapn style="float: left;">:</sapn>
          <div class="content">￥{{model.refundMoney}}</div>
          <div style="clear: both;"></div>
        </div>
        <div>
          <span class="contentTiele">原因</span>
          <sapn style="float: left;">:</sapn>
          <div class="content">{{model.refundReason_dictText}}</div>
          <div style="clear: both;"></div>
        </div>
        <div>
          <span class="contentTiele">说明</span>
          <sapn style="float: left;">:</sapn>
          <div class="content">{{model.refundExplain||''}}</div>
          <div style="clear: both;"></div>
        </div>
      </div>
      <div style="clear: both;"></div>
    </div>

    <!--拒绝退款原因-->
    <a-modal v-model="memoVisible" title="填写拒绝原因" ok-text="确认" cancel-text="取消" @ok="memoOk">
      <a-textarea maxlength="255" :rows="5" v-model="memo" placeholder="请输入拒绝原因"/>
    </a-modal>

    <!--物流信息-->
    <logistics-details-modal ref="logisticsDetailsModal"></logistics-details-modal>
  </a-modal>
</template>

<script>
    // import {httpAction} from '@/api/manage'
    import LogisticsDetailsModal from "@/views/express/modules/LogisticsDetailsModal"

    export default {
        name: "OrderGoodsModal",
        data() {
            return {
                title: "操作",
                visible: false,
                current: "0",
                memo: "",
                memoVisible: false,
                model: {
                    refundVoucher: "",
                },
                labelCol: {
                    xs: {span: 24},
                    sm: {span: 8},
                },
                wrapperCol: {
                    xs: {span: 24},
                    sm: {span: 12},
                },
                labelColMe: {
                    xs: {span: 24},
                    sm: {span: 4},
                },
                confirmLoading: false,
                refundVoucher: [],
                url: {
                    edit: "/order/refundRecord/check",
                    imgerver: window._CONFIG['fileURL']
                },
            }
        },
        components: {
            LogisticsDetailsModal,
        },
        created() {
        },
        methods: {
            info(record) {
                this.memo = "";
                this.model = Object.assign({}, record);
                if (this.model.refundVoucher) {
                    this.refundVoucher = this.model.refundVoucher.split(',');
                }
                var refundWay = record.refundWay;
                var refundStatus = record.refundStatus;
                //退款方式 1:退款退货   2:仅退款
                if (refundWay == 1) {
                    /*<a-step title="买家申请退货退款"/>
                            <a-step title="卖家处理退货申请"/>
                            <a-step title="买家退货"/>
                            <a-step title="退款完毕"/>*/
                    //申请状态 1:申请中  2:退款失败 3:退款成功 4:已撤销
                    // 5:等待卖家处理（卖家同意退货回款申请）
                    // 6:等待买家发货 7:等待卖家收货（等待卖家退款）
                    if (refundStatus == 5) {
                        this.current = 1;
                    }
                    if (refundStatus == 6 || refundStatus == 7 || refundStatus == 1) {
                        this.current = 2;
                    }
                    if (refundStatus == 8) {
                        this.current = 3;
                    }
                    //退款成功或拒绝退款或一撤销退款
                    if (refundStatus == 2 || refundStatus == 3 || refundStatus == 4) {
                        this.current = 4;
                    }
                }

                if (refundWay == 2) {
                    //申请状态 1:申请中  2:退款失败 3:退款成功 4:已撤销
                    // 5:等待卖家处理（卖家同意退货回款申请）
                    // 6:等待买家发货 7:等待卖家收货（等待卖家退款）
                    if (refundStatus == 1) {
                        this.current = 1;
                    }
                    if (refundStatus == 8) {
                        this.current = 2;
                    }
                    //退款成功或拒绝退款或一撤销退款
                    if (refundStatus == 2 || refundStatus == 3 || refundStatus == 4) {
                        this.current = 3;
                    }
                }
                this.visible = true;
            },
            close() {
                this.$emit('close');
                this.visible = false;
            },
            /**
             * @param type 1:同意退款（包含同意退货） 0:拒绝退款 （包含拒绝退货）
             * @param msg 拒绝原因
             */
            handleCheck(type, memo) {
                const that = this;
                let formData = {
                    id: that.model.id,
                    state: type,
                    memo: memo
                };
                this.$message.error('没有执行此方法')
                // httpAction(this.url.edit, formData, 'post').then((res) => {
                //     if (res.success) {
                //         that.$message.success(res.message);
                //         that.$emit('ok');
                //     } else {
                //         that.$message.warning(res.message);
                //     }
                // }).finally(() => {
                //     that.confirmLoading = false;
                //     that.close();
                // })
            },
            handleCancel() {
                this.close()
            },
            handleOk() {
                this.close()
            },
            //展示物流信息
            logisticsDetails: function () {
                var com = this.model.refundShippingCom;
                var num = this.model.refundShippingCode;
                var phone = this.model.refundReceiverMobile;
                this.$refs.logisticsDetailsModal.info(com, num, phone);
            },
            agreeRefund() {
                var that = this;
                if (!this.model.refundStatus) {
                    this.$confirm({
                        title: "确认退款",
                        content: "是否确认退款?",
                        onOk: function () {
                            that.handleCheck(1, "");
                        }
                    });
                } else {
                    that.handleCheck(1, "");
                }
            },
            refuseRefund() {
                this.memo = "";
                this.memoVisible = true;
            },
            memoOk() {
                var memo = this.memo;
                if (!memo) {
                    this.$message.warning('拒绝原因不能为空');
                    return;
                }
                this.memoVisible = false;
                this.handleCheck(0, memo);
            },
        }
    }
</script>

<style lang="less" scoped>
  .refundContent {
    border: 1px solid #d3d3d3;
    margin-top: 20px;

    .contentLeft {
      width: 700px;
      float: left;
    }

    .contentRight {
      float: left;
      width: 250px;
      border-left: 1px solid #d3d3d3;
      padding: 20px;

      .contentImg {
        width: 60px;
        height: 60px;
        line-height: 60px;
        float: left;
      }

      .contentTiele {
        float: left;
        font-size: 12px;
        color: #9c9c9c;
        width: 58px;
        display: inline-block;
        text-align: justify;
        text-align-last: justify;
        margin-bottom: 7px;
      }

      .content {
        margin-left: 65px;
        font-size: 12px;
      }
    }
  }
</style>